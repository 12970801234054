import { app, authentication } from '@microsoft/teams-js';
import * as Buffer from '@app/../../node_modules/buffer/index';


export class UserService {
  async getToken() {
    try{
      if (!app.isInitialized())
      {
        await app.initialize();
      }
      let res =  await authentication.getAuthToken();
      return res;
    }
    catch(error){
      throw error;
    }
  }

  getUser(token : string) {
    let user;
    console.log('token', token);
    if (token) {
      let segment = token.split('.')[1];
      console.log('segment', segment);
      if (segment) {
        let info = JSON.parse(Buffer.Buffer.from(segment, 'base64').toString());
        console.log('info', info);
        user = info.unique_name;
      }
    }
    return user;
  }

}
