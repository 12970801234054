import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {Buffer} from 'buffer';
import {UserService} from './services/user.service';
import {environment} from './environments/environment';

const userService = new UserService();

function useRedirectByToken(){
  const [process, setProcess] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const userToken = async () => {
      try{
        let token = await userService.getToken();
        if(token){
          let user = userService.getUser(token);
          var url = window.location.search;
          let newURL = url.replace("?url=", " ");
          // Decode the String
          let decodedUrl = Buffer.from(newURL, 'base64').toString('ascii');
          console.log('domain settings', environment.azureAd.allowDomain);
          environment.azureAd.allowDomain.forEach(element => {
            if(user.includes(element))
            {
              window.location.href = `${decodedUrl}&auth_token=${token}`;
              setProcess(true);
            }
            setLoading(false);
          });
        }
        setLoading(false);
      }
      catch(error){
        setLoading(false);
      }
    }
    userToken()
  },[]);

  return {
    process,isLoading
  }
}

function getDescription(isLoading: boolean, process: boolean){
  if(isLoading){
    return (<p>Welcome to teamsCX dashboard</p>);
  }
  else if (!process){
    return (<p>You don’t have permission to access this Dashboard.<br/> Please contact your admin for further information</p>);
  }
  
}

function App() {
  const {process, isLoading} = useRedirectByToken();
  const description = getDescription(isLoading, process);
  console.log('process and loading', {process, isLoading});
  return (
    <div className="App">
      <div >
        <div className='Main'>
          <img src={logo} alt="" width={50} height={50}></img>
          {
            description
          }
        </div>
      </div>
    </div>
  );
}


export default App;
